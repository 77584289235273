<template>
    <div class="container">
        <div class="row justify-content-center align-items-center mt-5">
            <div class="col-4 text-center bg-info">
                <b class="h2">{{$t('contact.contact')}}</b>
            </div>
        </div>
        <div class="row mt-5">
            <div class="col">
                <b-form v-on:keydown.enter="saveContact">
                    <div class="row">
                        <div class="col">
                            <b-form-group>
                                <label for="txt_name">{{$t('contact.input.name')}}</label>
                                <div v-if="$v.contact.name.$error" class="pull-right">
                                    <label class="text-danger" v-if="!$v.contact.name.required">{{$t('contact.input.name_required')}}</label>
                                </div>
                                <b-input-group>
                                    <b-input-group-prepend>
                                    <b-input-group-text><i class="icon-user"></i></b-input-group-text>
                                    </b-input-group-prepend>
                                    <b-form-input type="text" class="form-control"
                                    :placeholder="$t('contact.input.placeholder_name')" maxLength="50" v-on:input="$v.contact.name.$touch"
                                    v-model.trim="contact.name"/>
                                </b-input-group>
                            </b-form-group>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <b-form-group>
                            <label for="ddlEmail">{{$t('contact.input.email')}}</label>
                            <div v-if="$v.contact.email.$error" class="pull-right">
                                <label class="text-danger" v-if="!$v.contact.email.required">{{$t('common.email_required')}}</label>
                                <label class="text-danger" v-if="!$v.contact.email.email">{{$t('common.email_invalid')}}</label>
                            </div>
                            <b-input-group>
                                <b-input-group-prepend>
                                <b-input-group-text>@</b-input-group-text>
                                </b-input-group-prepend>
                                <b-form-input type="email" class="form-control" :placeholder="$t('contact.input.placeholder_email')" autocomplete="email"
                                maxLength="50" v-model.trim="contact.email" v-on:input="$v.contact.email.$touch"/>
                            </b-input-group>
                            </b-form-group>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col">
                            <b-form-group>
                                <label for="ddlPhone">{{$t('contact.input.phone')}}</label>
                                <div v-if="$v.contact.phone.$error" class="pull-right">
                                    <label class="text-danger" v-if="!$v.contact.phone.numeric">{{$t('common.phone_number_only')}}</label>
                                    <label class="text-danger" v-else-if="!$v.contact.phone.minLength">{{$t('contact.input.phone_ten_number')}}</label>
                                </div>
                                <b-input-group>
                                    <b-input-group-prepend>
                                    <b-input-group-text><i class="fa fa-mobile-phone"></i></b-input-group-text>
                                    </b-input-group-prepend>
                                    <b-form-input type="text" class="form-control" :placeholder="$t('contact.input.placeholder_phone')"
                                    maxLength="15" minLength="10"
                                    v-model.trim="contact.phone" v-on:input="$v.contact.phone.$touch"/>
                                </b-input-group>
                            </b-form-group>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col">
                            <b-form-group>
                            <label for="ddlContent">{{$t('contact.input.content')}}</label>
                            <b-input-group>
                                <b-input-group-prepend>
                                <b-input-group-text><i class="fa fa-comments-o"></i></b-input-group-text>
                                </b-input-group-prepend>
                                <b-form-textarea type="text" class="form-control" :rows="6"
                                :placeholder="this.$t('contact.input.placeholder_content')" maxLength="200"
                                v-model.trim="contact.content"/>
                            </b-input-group>
                            </b-form-group>
                        </div>
                    </div>
                </b-form>
                <b-row class="mb-5">
                    <b-col>
                    <a class="btn btn-info pull-right" style="text-transform:capitalize;"
                    href="#" @click="saveContact" block><i class="fa fa-check"></i> <span>{{$t('contact.button.submit')}}</span></a>
                    </b-col>
                </b-row>
            </div>
        </div>
    </div>
</template>

<script>
import { required, minLength,maxLength,email,numeric } from 'vuelidate/lib/validators'
import Loading from 'vue-loading-overlay'

export default {
  name: 'Register',
  components: {Loading},
  data () {
    return {
      contact:{
        id:null,
        name:null,
        phone:null,
        email: null,
        message:null
      }
    }
  },
  validations: {
    contact: {
      name: {
        required,
        maxLength:maxLength(50)
      },
      phone: {
        numeric,
        minLength: minLength(10)
      },
      email: {
        required,
        email,
        maxLength:maxLength(50)
      }
    }
  },
  mounted () {
  },
  methods: {
    async sendEmail(param){
      return await this.$store.dispatch(`st_user/register_ok_and_send_email`,param)
    },
    async saveContact () {
      const isValid = await this.isValid()
      if (!isValid) {
        return
      }
      var app = this

      var loader = app.$loading.show()
      debugger
      app.$store.dispatch('st_user/save_contact',contact)
      .then(function (resp) {
        app.clear()
        loader.hide()
      })
      .catch(function (resp) {
        app.$toastr.error(app.$t("common.error_message"),app.$t("common.message"))
        app.clear()
        loader.hide()
      })
    },
    _validations () {
      return new Promise(resolve => {
        if (this.$v.$error || !this.$v.$pending) {
            return resolve()
        }
        let poll = setInterval(() => {
        if (!this.$v.$pending) {
            clearInterval(poll)
            resolve()
        }
        }, 200)
      })
    },

    async isValid () {
      this.$v.$reset()
      this.$v.$touch()
      await this._validations()
      return Promise.resolve(!this.$v.$error)
    },
    clear(){
      this.user={id:null,name:null,email:null,phone:null}
      this.$v.$reset()
    }
  }
}
</script>